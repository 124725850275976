<template>
    <div class="login_bg">
        <div class="mb-3">
            <h1 class="display-1 login_logo">NFT</h1>
            <p class="display-6">TICKETS</p>
        </div>
        <div class="login_pic_wrap">
            <va-image class="login_pic" contain src="/img/ip.png"></va-image>
        </div>

        <va-card class="login_box text-center">
            <p>LOG IN WITH</p>
            <div class="login_btn">
                <va-button size="large" outline class="mb-3" gradient @click="login" :loading="btnLoading">
                    <va-image style="width:110px; height:24px;" contain class="mr-1" src="/img/meta2.png"></va-image>
                    </va-button>
                <va-button size="large" outline gradient @click="login('WalletConnect')" :loading="btnLoading">
                    <va-image style="width:110px; height:26px;" contain class="mr-1" src="/img/wallet.png"></va-image>
                </va-button>
            </div>
            <!-- <div class="login_btn">
                <va-button size="large" class="mb-3" gradient @click="login" :loading="btnLoading">
                    MetaMask
                </va-button>
                <va-button size="large" gradient @click="login" :loading="btnLoading">
                    WalletConnect
                </va-button>
            </div> -->
        </va-card>
        
    </div>
</template>
<script>
import { mapGetters,mapActions } from "vuex"
export default {
    data(){
        return{
            
        }
    },
    computed:{
        ...mapGetters([
            'btnLoading'
        ])
    },
    mounted(){
        
    },
    methods:{
        ...mapActions([
            'login',
            'checkLogin'
        ]),
    }
}
</script>
<style scoped lang='scss'>
@import "../assets/main.scss";
.login{    
    &_bg{
        min-height: 100vh;
        background-color: rgb(252, 251, 250);
        @include relativeCenter();
        text-align: center;
        p,h1{
            z-index: 99;
        }
        
        // &::before{
        //     content: "";
        //     width: 100%;
        //     height: 200px;
        //     @include center(39%, 48%);
        //     z-index: 0;
        //     background: url("/loginBg.png") no-repeat center center;
        //     background-size: contain;
        //     filter: grayscale(30%);
        // }
    }
    &_pic{
        &_wrap{
            height: 80px;
            overflow: hidden;
        }
        width: 100px;
        margin: 0 auto;
    }
    &_logo{
        color: $primaryColor;
        line-height: 1.5;
        &+p{
            color: #999;
        }
    }
    &_img{
        min-width: 100px;
        max-width: 280px;
        padding: 120px auto 50px;
        margin: 0 auto;
    }
    &_box{
        height: 200px;
        max-width: 350px;
        min-width: 270px;
        margin: 0 auto;
        position: relative;
        padding: 16px;
        p{
            color: #999;
            font-weight: 500;
            font-size: 14px;
        }
    }
    &_btn{
        @include center(55%);
        button{
            width: 180px;
            display: block;
            margin: 0 auto;
            :deep(.va-button__content){
                justify-content: center;
            }
            :deep(.va-progress-circle){
                width: 100% !important;
                top:50%;
                transform: translateY(-50%);
            }
        }
    }
}
// .logo_pic_wrap{
//      height: 1800px;
//     overflow: hidden;
// }
</style>